import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Link from "gatsby-link"
import { graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"
import Image from 'gatsby-image'

const Notes = ({data}) => {
    return (
      <Layout>
        <Helmet htmlAttributes={{ class:'notes' }} />
        <SEO title="Notes"
          description={data.site.siteMetadata.descriptions.notes} />
        <h1>Notes</h1>
        <div style={{ marginTop: '3rem', minHeight: '25rem' }}>
          {data.allNotes.edges.map(({node}, idx) =>
            <div key={'div'+node.fields.slug} >
            { idx === 0 && (
              <>
              <article style={{ display: 'block', minHeight: '2rem'}} key={node.fields.slug}>
                <Link
                      to={'../'+node.fields.slug}
                      style={{
                        textDecoration: `none`,
                        color: `inherit`,
                        width: '100%',
                      }}
                  >
                    <Image
                      fluid={node.frontmatter.cover.childImageSharp.fluid}
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: `0.4em`,
                        maxHeight: '30rem'
                      }}
                    />
                    <h3 style={{ display: 'inline-block', marginTop: '1.5rem' }}>{node.frontmatter.title}</h3>
                    <span> - {node.frontmatter.subtitle}</span>
                 </Link>
               </article>
               <hr className="divider" style={{ margin: '2.5rem auto'}} />
               </>
            )}
            { idx > 0 && (
            <article style={{ display: 'block', minHeight: '2rem'}} key={node.fields.slug}>
              <Link
                    to={'../'+node.fields.slug}
                    style={{
                      textDecoration: `none`,
                      color: `inherit`,
                      width: '100%',
                      display: 'flex',
                      marginBottom: '1rem'
                    }}
                >
                  <Image
                    fluid={node.frontmatter.cover.childImageSharp.fluid}
                    style={{
                      width: '20%',
                      minWidth: '6rem',
                      maxHeight: '6rem',
                      height: 'auto',
                      borderRadius: `0.4em`,
                    }}
                  />
                  <div style={{ marginLeft: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <h3 style={{ marginTop: '1.5rem' }}>{node.frontmatter.title}</h3>
                    <p style={{ margin: 0 }}>{node.frontmatter.subtitle}</p>
                  </div>
               </Link>
             </article>
           )}
             </div>
          )}
        </div>
      </Layout>
    )
}
export default Notes;

export const query = graphql `
  query NotesQuery {
    site {
      siteMetadata {
        title
        descriptions {
          notes
        }
      }
    }
    allNotes: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "note"}, visible: { eq: true}}}) {
      totalCount
      edges {
        node {
          frontmatter {
            type
            link
            title
            subtitle
            date(formatString: "YYYY/MM")
            background
            mobile
            web
            cover {
              childImageSharp {
                fixed {
                  src
                  srcSet
                  width
                  height
                }
                fluid(maxWidth: 2000,   traceSVG: {
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
    }
  }
`
